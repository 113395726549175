import AnswersUserId from '@app/components/Answers';
import { Link, useParams } from 'react-router';

export default function AdminAnswersUserId() {
  const { userId } = useParams();

  return (
    <div className="text-white p-4 w-full">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold mb-2">User: {userId}</h1>
        <Link className="underline ml-auto" to={`/admin/program/${userId}`}>
          Programme global
        </Link>
        <Link className="underline ml-4" to={`/admin/program-by-day/${userId}`}>
          Programme jour par jour
        </Link>
      </div>
      <div className="flex flex-row w-full">
        <div className="flex flex-col shrink-0 w-full">
          <h2 className="text-2xl font-bold mb-2">Réponses</h2>
          <AnswersUserId />
        </div>
      </div>
    </div>
  );
}
