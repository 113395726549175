import { ProgramActivity, UserProgram } from '@api/src/types/program';
import API from '@app/services/api';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const days = Array.from({ length: 70 }, (_, i) => i + 1);

export default function ProgramUserId() {
  const { userId } = useParams();
  const [userProgram, setUserProgram] = useState<UserProgram>({});
  const [basicProgram, setBasicProgram] = useState<UserProgram>({});
  const [programActivities, setProgramActivities] = useState<Array<ProgramActivity>>([]);
  const [start_program_date, setStartProgramDate] = useState<string>('');

  useEffect(() => {
    if (userId) {
      API.get({
        path: `/program/${userId}`,
      }).then((res) => {
        setUserProgram(res.data.programByDate);
        setProgramActivities(res.data.programActivities);
        setStartProgramDate(res.data.start_program_date);
      });
      API.get({
        path: `/program/BASIC`,
      }).then((res) => {
        setBasicProgram(res.data.programByDate);
      });
    }
  }, [userId]);

  return (
    <>
      <p className="shrink-0 border border-white/20 p-2">
        Démarrage: {dayjs(start_program_date).format('dddd DD MMMM YYYY')}
      </p>
      <div className="flex flex-col border-2 border-white/20  overflow-auto">
        <div className="flex flex-row">
          <p className="shrink-0 basis-96 border border-white/20 p-2">Activité</p>
          <div className="flex flex-col">
            <div className="flex flex-row">
              {days.map((day) => {
                return (
                  <div
                    key={day}
                    className="border border-white/20  size-8 shrink-0 flex items-center justify-center"
                  >
                    {day}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <p className="shrink-0 basis-96 border border-white/20 p-2">Nombre d'activités par jour</p>
          <div className="flex flex-col">
            <div className="flex flex-row">
              {days.map((day) => {
                const filled = basicProgram[day]?.length;
                return (
                  <div
                    key={day}
                    className="border border-white/20  size-8 shrink-0 flex items-center justify-center"
                  >
                    {filled}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row">
              {days.map((day) => {
                const filled = userProgram[day]?.length;
                return (
                  <div
                    key={day}
                    className="border border-white/20  size-8 shrink-0 flex items-center justify-center"
                  >
                    {filled}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {programActivities.map((activity, index) => (
          <div key={activity.id} className="flex flex-row ">
            <h2 className="shrink-0 basis-96 border border-white/20 p-2">
              <em className="opacity-30">{index + 1} - </em>
              {activity.id}
            </h2>
            <div className="flex flex-col border-white border justify-stretch">
              <div className="flex flex-row basis-1/2">
                {days.map((day) => {
                  const filled = !!basicProgram[day]?.find((a) => a.id.includes(activity.id));
                  return (
                    <div
                      key={day}
                      className="border border-white/20 w-8 h-8 min-h-full min-w-8 shrink-0 flex items-center justify-center"
                    >
                      {filled ? 'X' : ''}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row basis-1/2">
                {days.map((day) => {
                  const filled = !!userProgram[day]?.find((a) => a.id.includes(activity.id));
                  const basicFilled = !!basicProgram[day]?.find((a) => a.id.includes(activity.id));
                  return (
                    <div
                      key={day}
                      className={[
                        'border border-white/20 w-8 h-8 min-h-full min-w-8 shrink-0 flex items-center justify-center',
                        !basicFilled && filled ? 'bg-green-500' : '',
                        basicFilled && !filled ? 'bg-blue-500' : '',
                      ].join(' ')}
                    >
                      {filled ? 'X' : ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
