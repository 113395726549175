import ProgramUserId from '@app/components/Program';
import { Link, useParams } from 'react-router';

export default function AdminProgramUserId() {
  const { userId } = useParams();

  return (
    <div className="text-white p-4">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold mb-2">User: {userId}</h1>
        <Link className="underline ml-auto" to={`/admin/program-by-day/${userId}`}>
          Programme jour par jour
        </Link>
        <Link className="underline ml-4" to={`/admin/answers/${userId}`}>
          Questionnaire
        </Link>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold mb-2">Programme global</h2>
          <ProgramUserId />
        </div>
      </div>
    </div>
  );
}
