import Home from './Home';

export const ANDROID_APP_ID = 'com.nikicoach.app';
export const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.nikicoach.app';
export const IOS_APP_ID = '6740207801';
export const IOS_URL = 'https://apps.apple.com/fr/app/ton-coach-de-vie-niki-coach/id6740207801?l=fr-FR';
export const ROOT_URL = 'https://niki-coach.com';

export default function Download() {
  return (
    <>
      <Home />
      <script
        dangerouslySetInnerHTML={{
          __html: `
  function redirect() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    var macos = /Macintosh/.test(userAgent);
    if (ios || macos) {
      // window.location = myapp://element/{ELEMENT_ID};
      window.setTimeout(() => {
        window.location.replace('${IOS_URL}');
      }, 25)
      return
    }
    var android = /android/i.test(userAgent);
    if (android) {
      // window.location = myapp://element/{ELEMENT_ID};
      window.setTimeout(() => {
        window.location.replace('${ANDROID_URL}');
      }, 25)
      return
    }
    window.location.replace('${ROOT_URL}')
  }
  redirect()
`,
        }}
      />
    </>
  );
}
