import { Routes, Route } from 'react-router';
import { Navigation } from './components/Navigation';
import Home from './routes/Home';

// import LandingPage from './routes/landing';
// import Connexion from './routes/connexion';
// import { useEffect, type ReactElement } from 'react';
// import Chargement from './components/Chargement';
// import OfflineMode from './components/OfflineMode';
import * as Sentry from '@sentry/react';
import Blog from './routes/Blog';
import ArticlePage from './routes/Article';
import AdminProgramUserId from './routes/admin.program.$userId';
import AdminProgramByDayUserId from './routes/admin.program-by-day.$userId';
import AdminAnswersUserId from './routes/admin.answers.$userId';
import Privacy from './routes/privacy';
import EULA from './routes/eula';
import Download from './routes/Download';
// import { capture } from './services/sentry';
// import { UserRoles } from '@prisma/client';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
// 1 onglet QUIZZ,
// 1 onglet HABIT TRACKER GRATUIT (avec prompt personnalisé!!!),
// 1 onglet Recettes IG Bas 😃
// et 1 onglet ARTICLES SANT'E
function App() {
  return (
    <>
      <div className="min-h-screen bg-[#121212] flex flex-col">
        <main className=" bg-black">
          <SentryRoutes>
            <Route index path="/" element={<Home />} />
            <Route path="/download" element={<Download />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<ArticlePage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/eula" element={<EULA />} />
            <Route path="/admin/answers/:userId" element={<AdminAnswersUserId />} />
            <Route path="/admin/program/:userId" element={<AdminProgramUserId />} />
            <Route path="/admin/program-by-day/:userId" element={<AdminProgramByDayUserId />} />
          </SentryRoutes>
        </main>
        <Navigation />
      </div>
    </>
  );
}

// function RestrictedRoute({
//   children,
//   id,
//   roles = [],
// }: {
//   children: ReactElement;
//   id: string;
//   roles?: UserRoles[];
// }) {
//   const user = getMostFreshUser('RestrictedRoute ' + id);
//   const navigate = useNavigate();

//   // console.log(id, 'restricted route user', user);

//   useEffect(() => {
//     if (!user?.id) {
//       navigate('/app/connexion?type=compte-existant');
//     }
//     if (roles.length > 0 && !roles.some((role) => user?.roles.includes(role))) {
//       navigate('/app/connexion?type=compte-existant');
//     }
//   }, [user, navigate, roles]);

//   if (!user?.id) {
//     return <Chargement />;
//   }

//   return children;
// }

// function TestSentry() {
//   useEffect(() => {
//     capture('Test Sentry');
//   }, []);
//   return <div>Test Sentry</div>;
// }

export default App;
