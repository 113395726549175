interface ApiArgs {
  path?: string;
  query?: Record<string, string>;
  headers?: Record<string, string>;
  body?: Record<string, string | number>;
}

interface ApiExecuteArgs extends ApiArgs {
  method?: string;
}

class ApiService {
  host = process.env.NODE_ENV === 'development' ? 'http://localhost:6601' : 'https://api.niki-coach.com';
  scheme = process.env.NODE_ENV === 'development' ? 'http' : 'https';
  getUrl = (path: string, query: Record<string, string> = {}) => {
    return `${this.host}${path}?${new URLSearchParams(query).toString()}`;
  };
  execute = async ({ method = 'GET', path = '', query = {}, headers = {}, body }: ApiExecuteArgs) => {
    try {
      const config = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers,
        },
        body: body ? JSON.stringify(body) : null,
      };

      const url = this.getUrl(path, query);

      const response = await fetch(url, config);

      if (response.json) {
        try {
          const readableRes = await response.json();
          return readableRes;
        } catch (e) {
          console.log('ERROR IN RESPONSE JSON', response);
          console.log(e);
        }
      }

      return response;
    } catch (e) {
      console.log(' error in api');
      console.log(e);
      return {
        ok: false,
        error:
          "Veuillez nous excuser, cette erreur est inattendue : l'équipe technique a été prévenue. Veuillez retenter dans quelques instants ou nous contacter si l'erreur persiste.",
      };
    }
  };

  get = async (args: ApiArgs) => this.execute({ method: 'GET', ...args });
  post = async (args: ApiArgs) => this.execute({ method: 'POST', ...args });
  put = async (args: ApiArgs) => this.execute({ method: 'PUT', ...args });
  delete = async (args: ApiArgs) => this.execute({ method: 'DELETE', ...args });
}

const API = new ApiService();
export default API;
