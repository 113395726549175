import { ProgramActivity, UserProgram } from '@api/src/types/program';
import API from '@app/services/api';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const days = Array.from({ length: 70 }, (_, i) => i + 1);

export default function ProgramByDay() {
  const { userId } = useParams();
  const [userProgram, setUserProgram] = useState<UserProgram>({});
  const [programActivities, setProgramActivities] = useState<Array<ProgramActivity>>([]);
  const [startProgramDate, setStartProgramDate] = useState<string>('');
  useEffect(() => {
    if (userId) {
      API.get({
        path: `/program/${userId}`,
      }).then((res) => {
        setUserProgram(res.data.programByDate);
        setProgramActivities(res.data.programActivities);
        setStartProgramDate(res.data.start_program_date);
      });
    }
  }, [userId]);

  const today = dayjs();

  return (
    <div className="flex flex-row border-2 border-white/20 overflow-auto max-w-[98vw]">
      {days.map((day) => {
        return (
          <div key={day} className="flex flex-col basis-96 shrink-0">
            <div className="flex flex-row">
              <p
                key={day}
                className={[
                  'border border-white/20 basis-full grow text-left shrink-0 p-2 font-bold text-app-blue',
                  today.isBefore(dayjs(startProgramDate).add(day - 1, 'day'), 'day') ? '' : 'opacity-20',
                  today.isSame(dayjs(startProgramDate).add(day - 1, 'day'), 'day')
                    ? '!text-app-orange !opacity-100'
                    : '',
                ].join(' ')}
              >
                Jour {day} (
                {dayjs(startProgramDate)
                  .add(day - 1, 'day')
                  .format('dddd DD MMMM')}
                )
              </p>
            </div>
            {userProgram[day]
              ?.sort((a, b) => a.id.localeCompare(b.id))
              .map((activity, index) => {
                const activityData = programActivities.find((a) => activity.id.includes(a.id));
                return (
                  <p key={activity.id} className="border border-white/20 text-left shrink-0">
                    <em className="opacity-30">{index + 1} - </em>
                    <b>{activityData?.category}</b>&nbsp;-&nbsp;{activityData?.title}&nbsp;-&nbsp;
                    {activityData?.activity}
                  </p>
                );
              })}
          </div>
        );
      })}
    </div>
  );
}
