// import Image from '@app/components/MyImage';
// import TestimonialsAvatars from "./TestimonialsAvatars";
// import config from "~/config";

const Hero = ({
  imgSrc = '/woman-in-light.jpg',
  // imgAlt = 'Product Demo',
  h1 = 'La Naturopathie change les vies',
  description = <>METTEZ VOTRE TRUC ICI</>,
}) => {
  // <section className="bg-neutral text-neutral-content">
  return (
    <section
      className="bg-app-orange bg-cover bg-center bg-no-repeat min-h-screen h-screen overflow-hidden"
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      <div className="absolute inset-0 bg-white/20 pointer-events-none"></div>

      <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-16 px-8 py-8 lg:flex-row lg:gap-20 lg:py-20 h-full">
        <div className="flex flex-col items-center justify-center gap-10 text-center lg:gap-14 h-full">
          <h1 className="text-4xl font-extrabold tracking-tight md:-mb-4 lg:text-6xl text-app-lightBlack -mt-20">
            {/* Guérir c'est bien, prévenir aussi&nbsp;! */}
            {h1}
          </h1>
          <p className="text-balance text-lg leading-relaxed opacity-90 text-app-lightBlack text-center w-full drop-shadow-[0_1.2px_1.2px_rgba(256,256,256,0.8)]">
            {description}
          </p>
          {/* <button className="btn btn-primary btn-wide">En savoir plus</button> */}

          {/* <TestimonialsAvatars priority={true} /> */}
          <button
            type="button"
            className="rounded-3xl bg-black text-app-orange text-lg font-medium px-6 py-3 flex items-center gap-4"
            onClick={(e) => {
              e.preventDefault();
              // document.querySelector('#newsletter-form')?.scrollIntoView({ behavior: 'smooth' });
              const userAgent = navigator.userAgent || navigator.vendor || window.opera;
              const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
              const macos = /Macintosh/.test(userAgent);
              if (ios || macos) {
                // window.location = myapp://element/{ELEMENT_ID};
                window.setTimeout(() => {
                  window.location.replace(
                    'https://apps.apple.com/fr/app/ton-coach-de-vie-niki-coach/id6740207801?l=fr-FR',
                  );
                }, 25);
                return;
              }
              const android = /android/i.test(userAgent);
              if (android) {
                // window.location = myapp://element/{ELEMENT_ID};
                window.setTimeout(() => {
                  window.location.replace('https://play.google.com/store/apps/details?id=com.nikicoach.app');
                }, 25);
                return;
              }
              // document.querySelector('#newsletter-form')?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <img src="/adaptive-small-icon.png" alt="Niki Coach" className="size-8 aspect-square" />{' '}
            Télécharger Niki
          </button>
        </div>
        {/* <div className="lg:w-full">
          <Image src={imgSrc} alt={imgAlt} className="h-96 w-full rounded-md object-cover" />
        </div> */}
      </div>
    </section>
  );
};

export default Hero;
