import { Question, QuestionnaireAnswerForAi } from '@api/src/types/questionnaire';
import API from '@app/services/api';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export default function AnswersUserId() {
  const { userId } = useParams();
  const [answers, setAnswers] = useState<Array<QuestionnaireAnswerForAi>>([]);
  const [questionnaire, setQuestionnaire] = useState<Array<Question>>([]);
  const [userProfile, setUserProfile] = useState('');
  const [testUserProfile, setTestUserProfile] = useState('');
  const [isGettingUserProfile, setIsGettingUserProfile] = useState(false);

  useEffect(() => {
    if (userId) {
      API.get({
        path: `/questionnaire`,
        headers: {
          Authorization: userId,
        },
      }).then((res) => {
        console.log(res);
        setQuestionnaire(res.data.questionnaire);
      });
      API.get({
        path: `/questionnaire/answers`,
        headers: {
          Authorization: userId,
        },
      }).then((res) => {
        console.log(res);
        setAnswers(res.data.answers);
      });
      API.get({
        path: `/questionnaire/user-profile`,
        headers: {
          Authorization: userId,
        },
      }).then((res) => {
        console.log(res);
        setUserProfile(res.data.profile_summary);
      });
    }
  }, [userId]);

  return (
    <div className="flex flex-row overflow-auto w-full gap-x-4">
      <div className="flex flex-col border-2 border-white/20  overflow-auto basis-full">
        {questionnaire.map((question) => (
          <div key={question.question} className="flex flex-row ">
            <h4 className="shrink-0 basis-1/2 border border-white/20 p-2">{question.question}</h4>
            <p className="flex flex-col basis-1/2 border-white/20 grow border justify-center items-start">
              <span className="block">
                {/* @ts-expect-error Element implicitly has an 'any' type because index expression is not of type 'number' */}
                {answers[question.id]}
              </span>
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col border-2 border-white/20  overflow-auto shrink-0">
        <div className="m-4 flex justify-end">
          <button
            type="button"
            className="items-center justify-center rounded-full px-8 py-1 bg-app-orange text-black"
            onClick={() => {
              if (userId) {
                setIsGettingUserProfile(true);
                API.get({
                  path: `/questionnaire/user-profile`,
                  headers: {
                    Authorization: userId!,
                  },
                  query: {
                    test: 'true',
                  },
                }).then((res) => {
                  console.log(res);
                  setTestUserProfile(res.data.profile_summary);
                  setIsGettingUserProfile(false);
                });
              }
            }}
            disabled={isGettingUserProfile}
          >
            {isGettingUserProfile
              ? 'En cours...'
              : testUserProfile
                ? 'Tester de nouveau le prompt'
                : 'Tester le prompt'}
          </button>
        </div>
        <div className="flex flex-row max-w-3xl w-full">
          <div className="basis-1/2 p-4 border-r border-white/40">
            {userProfile.split('. ').map((paragraph) => {
              return (
                <React.Fragment key={paragraph}>
                  <p className="text-white">{paragraph}</p>
                  <br />
                </React.Fragment>
              );
            })}
          </div>
          <div className="basis-1/2 p-4">
            {!testUserProfile && (
              <p className="text-white/40 italic text-sm">
                Lorsque tu vas cliquer sur le bouton "Tester" ci-dessus, tu vas attendre quelques secondes que
                le nouveau prompt que tu as créé dans la base de données puisse travailler sur les réponses
                sur la gauche. Si le nouveau résultat te plait, tu peux copier coller en cliquant sur le
                bouton "copier coller" qui va apparaître en dessous du nouveau profil.
              </p>
            )}
            {testUserProfile.split('. ').map((paragraph) => {
              return (
                <React.Fragment key={paragraph}>
                  <p className="text-white">{paragraph}</p>
                  <br />
                </React.Fragment>
              );
            })}
            {testUserProfile && (
              <button
                type="button"
                className="items-center justify-center rounded-full px-2 py-1 bg-app-orange text-black"
                onClick={() => {
                  navigator.clipboard.writeText(testUserProfile);
                }}
              >
                Copier ce nouveau profil
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
