import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  BrowserRouter,
} from 'react-router';
import * as Sentry from '@sentry/react';
import tailwind from './tailwind.css?url';
import App from './App.tsx';

import '@af-utils/scrollend-polyfill';
import { ErrorBoundary } from 'react-error-boundary';
import UnexpectedError from './components/UnexpectedError.tsx';
import { capture } from './services/sentry.ts';
import { clearCache } from './services/indexed-db.ts';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
dayjs.locale('fr');

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://02c1b85c288d3b0775948e03c4db725c@o117731.ingest.us.sentry.io/4508444404416512',
    environment: `app-react-router-${import.meta.env.VITE_ENV}`,
    release: __VITE_BUILD_ID__,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    maxValueLength: 10000,
    normalizeDepth: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    ignoreErrors: [
      'Network request failed',
      // "Failed to fetch",
      'NetworkError',
      // ???
      'withrealtime/messaging',
      // This error seems to happen only in firefox and to be ignorable.
      // The "fetch" failed because user has navigated.
      // Since other browsers don't have this problem, we don't care about it,
      // it may be a false positive.
      'AbortError: The operation was aborted',
      // Sur safari, on a des erreur de type "TypeError: cancelled" qui seraient liées
      // au bouton "X" (ou refresh) pressé pendant un fetch. Il semblerait que la meilleure
      // approche soit de les ignorer.
      // Cf: https://stackoverflow.com/a/60860369/978690
      'TypeError: cancelled',
      'TypeError: annulé',
    ],
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <link rel="stylesheet" href={tailwind} />

    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={UnexpectedError}
        onError={(error, componentStack) => {
          capture(error, { extra: { componentStack } });
        }}
        onReset={() => {
          clearCache().then(() => {
            window.location.href = '/';
          });
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
);
