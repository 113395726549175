export default function Privacy() {
  return (
    <div className="privacy-policy text-white min-h-screen flex flex-col space-y-4 p-4">
      <h1>Privacy Policy</h1>

      <p>Last updated: {new Date().toLocaleDateString()}</p>

      <h2>Overview</h2>
      <p>
        We respect your privacy and are committed to protecting any information shared while using our app.
        This Privacy Policy explains how we collect, use, and safeguard your information.
      </p>

      <h2>Information Collection</h2>
      <p>
        <strong>Anonymous Data Collection:</strong> Most data we collect is completely anonymous. We do not
        collect personally identifiable information unless you opt for our coaching subscription service.
      </p>
      <p>
        <strong>Coaching Subscription Data:</strong> If you subscribe to our coaching services, we will
        collect your email address and/or phone number. This information is strictly used for communication
        purposes related to coaching sessions and will never be shared with third parties.
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        We store anonymous data in our database to improve app functionality and features. For coaching
        subscribers, we securely store contact information (email/phone) solely for the purpose of scheduling
        and conducting coaching sessions. This information is never shared with external parties.
      </p>

      <h2>Data Retention and Deletion</h2>
      <p>
        <strong>App Deletion:</strong> If you delete the app from your mobile device, we will retain coaching
        subscription contact information until the subscription is cancelled. All other anonymous data will be
        disconnected from your usage.
      </p>
      <p>
        <strong>Data Deletion Requests:</strong> If you request deletion of your data, we will promptly remove
        all associated anonymous data from our systems. To request data deletion, please contact us at
        <a href="mailto:marine.ou.arnaud.ambroselli@gmail.com">marine.ou.arnaud.ambroselli@gmail.com</a>.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
        new Privacy Policy on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at{' '}
        <a href="mailto:marine.ou.arnaud.ambroselli@gmail.com">marine.ou.arnaud.ambroselli@gmail.com</a>.
      </p>
    </div>
  );
}
