import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

type FileFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
  sizeInBytes: number;
};

type File = {
  id: number;
  documentId: string;
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  formats: {
    small: FileFormat;
    medium: FileFormat;
    thumbnail: FileFormat;
    large?: FileFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
};

type Block = {
  __component: string;
  id: number;
  body?: string;
  title?: string;
  file?: File;
  files?: File[];
};

type Article = {
  id: number;
  documentId: string;
  title: string;
  description: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  author: {
    id: number;
    documentId: string;
    name: string;
  };
  cover: {
    id: number;
    documentId: string;
    url: string;
    alternativeText: string | null;
    caption: string | null;
  };
  blocks: Block[];
};

type ArticleResponse = {
  data: Article[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export default function ArticlePage() {
  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetch(
      `https://strapi.niki-coach.com/api/articles?filters[slug][$eq]=${slug}&populate[cover][fields][0]=url&populate[cover][fields][1]=alternativeText&populate[blocks][populate]=*&populate[author][populate]=*`,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch article');
        }
        return response.json();
      })
      .then((data: ArticleResponse) => {
        if (data.data.length > 0) {
          setArticle(data.data[0]);
        } else {
          throw new Error('Article not found');
        }
        setLoading(false);
      })
      .catch((error: Error) => {
        console.error('Error fetching article:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center text-app-orange">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center text-app-orange">
        Error: {error}
      </div>
    );
  }

  if (!article) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center text-app-orange">
        Article not found.
      </div>
    );
  }

  const title = `${article.title} | Niki Coach`;

  return (
    <>
      {/* Add Meta Tags for SEO */}
      <title>{title}</title>
      <meta name="description" content={article.description} />
      <meta name="keywords" content={`${article.title}, blog, article`} />
      <meta name="author" content={article.author?.name || 'Unknown Author'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={article.description} />
      <meta property="og:image" content={`https://strapi.niki-coach.com${article.cover.url}`} />
      <meta property="og:url" content={`https://yourwebsite.com/blog/${article.slug}`} />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={article.description} />
      <meta name="twitter:image" content={`https://strapi.niki-coach.com${article.cover.url}`} />

      <div className="min-h-[calc(100vh-4rem)] flex flex-col items-center py-10 text-app-orange">
        <div className="w-full max-w-4xl px-4">
          <h1 className="text-4xl font-extrabold tracking-tight lg:text-6xl mb-6">{article.title}</h1>

          {article.cover && (
            <img
              src={`https://strapi.niki-coach.com${article.cover.url}`}
              alt={article.cover.alternativeText || article.title}
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
          )}

          <div className="prose prose-lg max-w-none">
            {article.blocks.map((block, index) => {
              switch (block.__component) {
                case 'shared.rich-text':
                  return (
                    <ReactMarkdown
                      key={`${block.id}-${index}`}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        u: ({ node, ...props }) => <u style={{ textDecoration: 'underline' }} {...props} />,
                      }}
                    >
                      {block.body || ''}
                    </ReactMarkdown>
                  );
                case 'shared.quote':
                  return (
                    <blockquote
                      key={`${block.id}-${index}`}
                      className="border-l-4 border-app-orange pl-4 italic"
                    >
                      <p>{block.body}</p>
                      {block.title && <footer className="mt-2 text-gray-600">— {block.title}</footer>}
                    </blockquote>
                  );
                case 'shared.media':
                  return (
                    <div key={`${block.id}-${index}`} className="my-6">
                      {block.file && (
                        <img
                          src={`https://strapi.niki-coach.com${block.file.url}`}
                          alt={block.file.alternativeText || ''}
                          className="w-full rounded-lg"
                        />
                      )}
                    </div>
                  );
                case 'shared.slider':
                  return (
                    <div key={`${block.id}-${index}`} className="my-6">
                      {block.files && (
                        <div className="flex overflow-x-auto gap-4">
                          {block.files.map((file) => (
                            <img
                              key={file.id}
                              src={`https://strapi.niki-coach.com${file.url}`}
                              alt={file.alternativeText || ''}
                              className="w-64 h-64 object-cover rounded-lg"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>

          <small className="text-gray-400 mt-6 block">
            Published on: {new Date(article.publishedAt).toLocaleDateString()}
          </small>
        </div>
      </div>
    </>
  );
}
