export default function EULA() {
  return (
    <div className="eula text-white min-h-screen flex flex-col space-y-4 p-4">
      <h1>End-User License Agreement (EULA)</h1>

      <p>Last updated: {new Date().toLocaleDateString()}</p>

      <p>
        This End-User License Agreement ("EULA") is a legal agreement between you (either an individual or a
        single entity) and AMBROSELLI.IO, located at 14 le vauchel 76540 saint pierre en port, France
        ("Company", "we", "us", or "our") for the mobile application niki ("App").
      </p>

      <p>
        By downloading, installing, accessing, or using the App, you agree to be bound by the terms and
        conditions of this EULA. If you do not agree to the terms of this EULA, do not download, install,
        access, or use the App.
      </p>

      <h2>1. License Grant</h2>
      <p>
        Subject to your compliance with this EULA, the Company grants you a limited, non-exclusive,
        non-transferable, revocable license to download, install, and use the App for your personal,
        non-commercial purposes strictly in accordance with this EULA and any other terms and conditions that
        may apply to the App.
      </p>

      <h2>2. Restrictions</h2>
      <p>You agree not to, and you will not permit others to:</p>
      <ul className="list-disc pl-6">
        <li>
          License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
          commercially exploit the App.
        </li>
        <li>Copy or use the App for any purpose other than as permitted under this EULA.</li>
        <li>
          Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part
          of the App.
        </li>
        <li>
          Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of
          the Company or its affiliates, partners, suppliers or licensors.
        </li>
        <li>
          Use the App in any way that violates any applicable local, state, national, or international law or
          regulation.
        </li>
      </ul>

      <h2>3. Data Collection and Privacy</h2>
      <p>
        The App collects certain data to provide its services, including responses to the 50-question
        assessment and progress tracking for the 66-day program. This data is stored in our database and is
        used to provide personalized recommendations and track your progress.
      </p>
      <p>
        For users who subscribe to our coaching services, we collect and store contact information (email
        address and/or phone number) which is necessary to provide coaching services. This contact information
        is used exclusively for communication related to coaching sessions and is never shared with third
        parties.
      </p>
      <p>
        We use third-party services including Sentry for error tracking and Plausible for analytics. These
        services help us improve the App's performance and user experience.
      </p>
      <p>
        For more information about how we collect, use, and share your information, please review our Privacy
        Policy.
      </p>

      <h2>4. Subscription and Billing</h2>
      <p>
        The App offers subscription services. Details about subscription options, pricing, and payment terms
        are available in the App description in the Apple App Store and Google Play Store. All purchases and
        subscriptions are subject to the terms and conditions of the respective app store from which you
        downloaded the App.
      </p>

      <h2>5. Intellectual Property Rights</h2>
      <p>
        The App, including but not limited to text, graphics, logos, icons, images, audio clips, digital
        downloads, data compilations, and software, is the property of AMBROSELLI.IO and is protected by
        international copyright, trademark, patent, trade secret, and other intellectual property or
        proprietary rights laws.
      </p>

      <h2>6. Age Restrictions</h2>
      <p>
        The App is intended for users of all ages. However, if you are under the age of 18, you should review
        this EULA with your parent or guardian to make sure that you and your parent or guardian understand
        it.
      </p>

      <h2>7. Termination</h2>
      <p>
        This EULA is effective until terminated by you or us. Your rights under this EULA will terminate
        automatically without notice if you fail to comply with any of its terms. You may terminate this EULA
        by deleting the App and all copies from your mobile device.
      </p>
      <p>
        Upon termination, all rights granted to you under this EULA will also terminate, and you must cease
        all use of the App and delete all copies of the App from your mobile device.
      </p>
      <p>You may also request termination and data deletion by contacting us at arnaud@ambroselli.io.</p>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
      </p>
      <p>
        THE COMPANY DOES NOT WARRANT THAT THE APP WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE APP
        WILL BE UNINTERRUPTED OR ERROR-FREE.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY
        SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
        DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR
        LOSSES) ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE APP, HOWEVER CAUSED, REGARDLESS
        OF THE THEORY OF LIABILITY AND EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </p>

      <h2>10. Health Disclaimer</h2>
      <p>
        The App provides general well-being and health-related information and is intended for informational
        purposes only. It is not a substitute for professional medical advice, diagnosis, or treatment. Always
        seek the advice of your physician or other qualified health provider with any questions you may have
        regarding a medical condition.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        This EULA is governed by and construed in accordance with the laws of France, without regard to its
        conflict of law principles. Any legal action or proceeding arising under this EULA will be brought
        exclusively in the courts located in France, and you hereby consent to personal jurisdiction and venue
        therein.
      </p>

      <h2>12. Changes to this EULA</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace this EULA at any time. If a
        revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
        What constitutes a material change will be determined at our sole discretion.
      </p>

      <h2>13. Contact Information</h2>
      <p>
        If you have any questions about this EULA, please contact us at{' '}
        <a href="mailto:arnaud@ambroselli.io">arnaud@ambroselli.io</a>.
      </p>

      <h2>14. Entire Agreement</h2>
      <p>
        This EULA constitutes the entire agreement between you and the Company regarding your use of the App
        and supersedes all prior and contemporaneous written or oral agreements between you and the Company.
      </p>
    </div>
  );
}
