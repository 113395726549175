import ProgramByDay from '@app/components/ProgramByDay';
import { Link, useParams } from 'react-router';

export default function AdminProgramByDayUserId() {
  const { userId } = useParams();

  return (
    <div className="text-white p-4">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold mb-2">User: {userId}</h1>
        <Link className="underline ml-auto" to={`/admin/program/${userId}`}>
          Programme global
        </Link>
        <Link className="underline ml-4" to={`/admin/answers/${userId}`}>
          Questionnaire
        </Link>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold mb-2">Programme jour par jour</h2>
          <ProgramByDay />
        </div>
      </div>
    </div>
  );
}
