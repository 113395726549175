import { useEffect, useState } from 'react';
import { Link } from 'react-router';

type GetArticlesResponse = {
  data: Array<{
    id: number;
    documentId: string;
    title: string;
    description: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    cover: {
      id: number;
      documentId: string;
      url: string;
      formats: {
        thumbnail: {
          url: string;
        };
      };
    };
  }>;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export default function Blog() {
  const [articles, setArticles] = useState<GetArticlesResponse['data']>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch articles from Strapi API
    fetch('https://strapi.niki-coach.com/api/articles?populate=cover')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }
        return response.json();
      })
      .then((data: GetArticlesResponse) => {
        setArticles(data.data);
        setLoading(false);
      })
      .catch((error: Error) => {
        console.error('Error fetching articles:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center text-app-orange">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center text-app-orange">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-4rem)] flex flex-col items-center py-10 text-app-orange">
      <title>Blog | Niki | 66 jours pour transformer ta vie</title>
      <h1 className="text-4xl font-extrabold tracking-tight lg:text-6xl mb-10">Niki - Blog</h1>

      {articles.length === 0 ? (
        <p>Bientôt...</p>
      ) : (
        <div className="w-full max-w-6xl px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map((article) => (
            <Link
              key={article.id}
              to={`/blog/${article.slug}`}
              className="flex flex-col bg-app-lightBlack border border-app-orange rounded-lg shadow-lg overflow-hidden max-w-96 place-self-center w-full"
            >
              {article.cover && (
                <img
                  src={`https://strapi.niki-coach.com${article.cover.formats.thumbnail.url}`}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-6 flex flex-col flex-grow">
                <h2 className="text-2xl font-bold text-app-orange mb-2">{article.title}</h2>
                <p className="text-app-orange opacity-45 flex-grow">{article.description}</p>
                <small className="text-app-orange opacity-35 mt-4">
                  Published on: {new Date(article.publishedAt).toLocaleDateString()}
                </small>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
